export interface PageUrlParam {
  home: {}
  search: { sessionId: string, utm?: string, kword?: string }
  login: {
    type?: 'login' | 'register'
  }
  success: {}
  install: { browser?: string; from?: string }
  test: { promptId: string }
  intro: {}
  uninstall: { v?: string, userid?: string }
  share: { searchShareId: string }
  /** h5 */
  h5_share: { searchShareId: string }
}

export const PAGE_LINKS = {
  home: '/',
  search: '/search',
  login: '/login',
  success: '/success',
  install: '/install',
  test: '/test',
  intro: '/intro',
  uninstall: '/uninstall',
  share: '/share',
  /** H5 */
  h5_share: '/h5/share'
}
