import { get, post } from '../common'

export namespace share {
  export function genShareLink(
    searchSessionId: string,
    recordList: Array<{ id: string, question: boolean, answer: boolean }>
  ) {
    return post<{ searchShareId: string }>('/search/session/share/add', { searchSessionId }, { recordList })
  }

  export function shareLinkResult(searchShareId: string) {
    return get<{
      searchShare: Array<{ question?: string, answer?: string, searchResults?: Session.Chat.SearchResult[] }>
    }>('/search/session/share/one', { searchShareId })
  }
}