import { commonUtils } from '@/helpers/commonUtils'
// import { storageHelper } from '@/helpers/storageHelper'
import { computed, makeObservable, observable, runInAction } from 'mobx'
import { ee } from '../eventEmitter/eventEmitter'
import api from '@/api'
import { dayjsHelper } from '@/helpers/dayjsHelper'
import { storageHelper } from '@/helpers/storageHelper'


export const DEFAULT_NICKNAME = 'momo'
const AVATAR_PREFIX = 'default-avatar-'
/** 总共有 9 个默认头像，后续增加了，需要修改 */
const DEFAULT_AVATARS_LENGTH = 9

class UserService {
  @observable userData: User.Info | null = null

  @observable version = '1.0.0'
  @observable updateDate = '2024-04-13'
  @observable downloadUrl = 'https://cdn.yiban.io/extensions/Ruta-AI搜索_1_0_0.zip'
  @observable ruta360DownloadUrl: string = 'https://download.chrome.360.cn/ext/prod-cfhempnebmdlbnpiimgofgjfadjcejom-d333ecde491db106fccc625a51c66aa0.crx'
  @observable userServiceInitialed = false
  @observable isInitialed = false
  @observable userAvatarOrder = 1
  avatarTimer: any = 0

  constructor() {
    makeObservable(this)
    this.init()
    this.updateVersionInfo()
    this.initSEMKey()
  }

  @computed get userNickname() {
    if (this.userData && this.userType === 'normal') {
      return this.userData.nickname || DEFAULT_NICKNAME
    } else {
      return DEFAULT_NICKNAME
    }
  }

  @computed get userAvatar() {
    return commonUtils.getStaticFile(`userBox/${AVATAR_PREFIX}${this.userAvatarOrder}.png`)
  }

  @computed get isLogin() {
    return !!this.userData && this.userType === 'normal'
  }

  @computed get userType() {
    if (!this.userData) {
      return null
    } else if (this.userData.wxOpenid) {
      return 'normal'
    } else {
      return 'visitor'
    }
  }

  public changeUserDefaultAvatar() {
    const nextOrder = this.userAvatarOrder === DEFAULT_AVATARS_LENGTH ? 1 : this.userAvatarOrder + 1
    this.userAvatarOrder = nextOrder
    clearTimeout(this.avatarTimer)
    const nickname = this.userData?.nickname || DEFAULT_NICKNAME
    this.avatarTimer = setTimeout(async () => {
      await api.user.changeUserAvatarAndNickname({ avatar: `${AVATAR_PREFIX}${nextOrder}`, nickname })
      commonUtils.sendExtensionMessage('refreshUserInfo', {})
    }, 300)
  }

  public async changeUserNickname(name: string) {
    await api.user.changeUserAvatarAndNickname({ avatar: `${AVATAR_PREFIX}${this.userAvatarOrder}`, nickname: name })
    commonUtils.sendExtensionMessage('refreshUserInfo', {})
  }

  public async init() {
    this.isInitialed = false
    await this.updateUserInfo()
    this.isInitialed = true
  }

  /** 主动(手动)登出的情况 */
  public async logout() {
    await api.user.logout()
    this.userData = null
    commonUtils.sendExtensionMessage('logout', {})
  }

  public async getUserInfo() {
    if (this.userData === null) {
      await this.updateUserInfo()
    }
    return this.userData
  }

  public async updateUserInfo() {
    try {
      const { userData } = await api.user.getUserInfo()
      runInAction(() => {
        let data = userData
        if (userData) {
          data = {
            ...userData,
            nickname: userData.nickname || DEFAULT_NICKNAME
          }
          if (userData.avatar) {
            this.userAvatarOrder = Number(userData.avatar.replace(AVATAR_PREFIX, ''))
          } else {
            this.userAvatarOrder = 1
          }
        }
        this.userData = data
      })
      // storageHelper.set({ userData })
    } catch (error) {
      runInAction(() => {
        this.userData = null
      })
      // storageHelper.set({ userData: null })
    }
    this.userServiceInitialed = true
    ee.emit('userServiceInitialed', {})
  }

  public async refreshUserSessionCount() {
    if (this.userData && Boolean(this.userData.wxOpenid)) { return }
    this.updateUserInfo()
  }

  public openLoginTab() {
    window.open(`/login?redirectUrl=${encodeURIComponent(window.location.href)}`)
  }

  public trackUserRecord(actionType: string) {
    const token = commonUtils.genVisitorTokenStr()
    try {
      api.user.postVisitorRecord({ token, actionType })
    } catch (error) {
      //do nothing
    }
  }

  private initSEMKey() {
    try {
      const { utm, kword, bd_vid, bd_url, referer } = commonUtils.getUrlArgs()
      if (utm) {
        storageHelper.set({ utm })
      }
      if (
        document.referrer &&
        document.referrer.indexOf('ruta') === -1 &&
        document.referrer.indexOf('localhost') === -1
      ) {
        if (!storageHelper.get(['referer']).referer) {
          storageHelper.set({ referer: document.referrer })
        }
      }
      if (referer) {
        if (!storageHelper.get(['referer']).referer) {
          storageHelper.set({ referer })
        }
      }
      if (kword) {
        const decodedKword = decodeFromGb2312(kword)
        storageHelper.set({ kword: decodedKword })
      }

      // 并不意味着 location.href 中有 bd_vid 就是从百度投放来的
      // 还有可能是从自定义投放页面来的 此时会携带 bd_url
      if (bd_vid || bd_url) {
        // 优先 bd_url 其次 location.href
        const bd_url_vid = commonUtils.getUrlArgs(bd_url || 'bd_url').bd_vid
        if (bd_url && bd_url_vid) {
          storageHelper.set({ bdVid: bd_url_vid })
          storageHelper.set({ bdUrl: bd_url })
        } else {
          storageHelper.set({ bdVid: bd_vid })
          storageHelper.set({ bdUrl: window.location.href })
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  private async updateVersionInfo() {
    try {
      const {
        chromeVersion,
        ruta360DownloadUrl,
        extensionUpdateTime,
      } = await api.config.urlConfig()
      const rtChromeDownloadUrl = `https://cdn.yiban.io/extensions/Ruta-AI搜索_${chromeVersion.replace(
        /\./g,
        '_'
      )}.zip`
      this.version = chromeVersion
      this.downloadUrl = rtChromeDownloadUrl
      this.ruta360DownloadUrl = ruta360DownloadUrl
      const updateDate = dayjsHelper.formatTime(extensionUpdateTime * 1000, 'YYYY-MM-DD')
      this.updateDate = updateDate
    } catch (error) {
      // do nothing
    }
  }
}

export const userService = new UserService()
