class EnvHelper {
  get extensionPrefix() {
    return 'ruta_webapp'
  }

  get isProd() {
    return process.env.NODE_ENV === 'production'
  }

  get productName() {
    return 'Ruta'
  }

  get host() {
    return process.env.REACT_APP_HOST
  }

  get webHost() {
    return process.env.REACT_APP_WEB_HOST
  }

  get apiHost() {
    return this.webHost
  }

  get apiBasePath() {
    return '/ruta_api'
  }
}

export const envHelper = new EnvHelper()
