import { createSearchParams, useNavigate, } from 'react-router-dom'
import { PAGE_LINKS, PageUrlParam } from './pageLinks'


type PageName = keyof typeof PAGE_LINKS

export const useNavigateWithParam = () => {
  const navigate = useNavigate()
  return <P extends PageName>(pageName: P, payload?: PageUrlParam[P], replace = false) => {
    const search = createSearchParams(payload).toString()
    navigate({ pathname: PAGE_LINKS[pageName], search, }, { replace })
  }
}


