import { ApiBaseResult, get, post } from '../common'

export namespace user {
  export function getUserInfo(): Promise<ApiBaseResult & { userData: User.Info | null }> {
    return get('/user/me')
  }
  export function changeUserAvatarAndNickname(p: { nickname: string, avatar: string }) {
    return post('/user/update', {}, p)
  }

  export function logout(): Promise<ApiBaseResult> {
    return get('/user/logout')
  }

  export function postVisitorRecord(data: { token: string; actionType: string }) {
    return post('/visitor/record', {}, data)
  }
}
