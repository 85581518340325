import { envHelper } from '@/helpers/envHelper'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { useEffect } from 'react'

export enum GptStreamEvent {
  AI_RESP_STREAM = "ai_resp_stream",
  AI_END_STREAM = "ai_end_stream",
  AI_ERROR = "error"
}
export namespace esr {
  export function create(taskId: string) {
    return new EventSourcePolyfill(`${envHelper.apiBasePath}/gpt/stream/pull?stream_id=${taskId}`, {
      headers: {}
    })

  }
}

export const useGptStream = (event: typeof GptStreamEvent[keyof typeof GptStreamEvent], handler: (event: MessageEvent) => void, eventSource?: EventSource,) => {
  useEffect(() => {
    if (!eventSource) { return }
    eventSource.addEventListener(event, handler)
    return () => {
      eventSource.removeEventListener(event, handler)
    }
  }, [eventSource, event, handler])
}
