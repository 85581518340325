import { ApiProxy } from './ApiProxy'

export type ApiBaseResult = ApiTypes.ApiBaseResult
type APIQueryArgCollection = ApiTypes.APIQueryArgCollection
type APIBodyArgCollection = ApiTypes.APIBodyArgCollection

/**
 * 发送 get 请求
 * @param path 请求路径
 * @param urlArgs query参数，参数名会自动被转化成下划线命名法
 */
export function get<T = {}>(path: string, urlArgs?: APIQueryArgCollection): Promise<T> {
  return new ApiProxy().sendRequest('GET', path, urlArgs)
}

/**
 * 发送 post 请求
 * @param path 请求路径
 * @param urlArgs query参数，参数名会自动被转化成下划线命名法
 * @param bodyArgs body参数，参数名会自动被转化成下划线命名法
 */
export function post<T = {}>(
  path: string,
  urlArgs?: APIQueryArgCollection,
  bodyArgs?: APIBodyArgCollection,
  camelize?: boolean
): Promise<T> {
  return new ApiProxy().sendRequest('POST', path, urlArgs, bodyArgs, camelize)
}
