import { get, post } from '../common'

export namespace config {
  export function urlConfig() {
    return get<{
      // staffQrUrl: string
      chromeVersion: string
      extensionUpdateTime: TimestampSec
      ruta360DownloadUrl: string
      // hyChromeDownloadUrl: string
      // version360: string
    }>('/url_config')
  }

  export function postUninstallRecord(
    data: {
      /** 点击升级插件 */
      clickUpgrade?: boolean
      /** 插件版本 */
      extensionVersion?: string
      /** 卸载原因 */
      reason?: string
      /** 记录的id */
      recordId?: string
      /** 用户id */
      userId?: string
    }
  ) {
    return post<{ recordId: string }>('/uninstall_record', {}, data)
  }

}