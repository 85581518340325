import { get, post } from '../common'

export namespace session {
  export function list() {
    return get<{ sessionList: Session.Detail[] }>('/search/session/list')
  }

  /** 新建会话 */
  export function _add(p: { targetLang: string; question: string }, { promptId, provider = 'zhipuai' }: {
    promptId: string
    provider: PostDataTypes.Provider
  }) {
    return post<{ searchSessionId: string }>('/search/session/add', { promptId, provider }, p)
  }

  /** 新建会话 */
  export function add(p: { targetLang: string; question: string }) {
    return post<{ searchSessionId: string }>('/search/session/add', {}, p)
  }

  export function remove(searchSessionId: string) {
    return post('/search/session/delete', { searchSessionId }, {})
  }

  export function update(searchSessionId: string, p: { title: string }) {
    return post('/search/session/update', { searchSessionId }, p)
  }

  /** 会话详情 : 会话详情, 里面每条消息都有status和stream_id字段，处理方式和focus那边一样,如果status='waiting'就持续请求/search/session/chat/one*/
  export function one(searchSessionId: string) {
    return get<{
      searchRecordList: Session.Record[]
    }>('/search/session/one', { searchSessionId })
  }

  /** 单个问题详情 */
  export function chatDetail(p: { searchRecordId: string }) {
    return get<{
      searchRecord: Session.Record
    }>('/search/session/chat/one', p)
  }

  export function chatUpdate(searchRecordId: string, body: { status: Session.RecordFavStatus }) {
    return post('/search/session/chat/update', { searchRecordId }, body)
  }

  export function chatRemove(searchRecordId: string) {
    return post('/search/session/chat/delete', { searchRecordId })
  }

  /** 继续提问 */
  export function chatAsk(
    args: { searchSessionId: string },
    body: { targetLang: string; question: string }
  ) {
    return post<{
      searchRecord: Session.Record
    }>('/search/session/chat_stream', args, body)
  }

  /** 增加预设 prompt（测试环境专用） */
  export function createPrompt(data: {
    initPrompt: string
    continuePrompt: string
    queryTransPrompt: string
  }) {
    return post<{
      promptId: number
    }>('/search/prompt/add', {}, data)
  }

  /** 获取预设 prompt 列表（测试环境专用） */
  export function getPromptList() {
    return get<{ promptList: Session.PromptData[] }>('/search/prompt/list')
  }

  /** 获取预设 prompt（测试环境专用） */
  export function getPrompt(id: string) {
    return get<{ prompt: Session.PromptData }>('/search/prompt/one', { promptId: id })
  }

  /** 更新预设 prompt（测试环境专用） */
  export function updatePrompt(promptId: string, data: {
    initPrompt: string
    continuePrompt: string
    queryTransPrompt: string
  }) {
    return post<{
      promptId: string
    }>('/search/prompt/update', { promptId }, data)
  }

}
