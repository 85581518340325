import { ElementType, Suspense, lazy } from 'react'
// import AmeSpin from '../components/AmeSpin/AmeSpin'
import PageContainer from '@/pages/PageContainer/PageContainer'
// import TestPage from '@/pages/TestPage/TestPage'
import { createBrowserRouter } from 'react-router-dom'
import { PAGE_LINKS } from './pageLinks'

const Loadable = (Component: ElementType) => (props: any) =>
(
  // <Suspense fallback={<AmeSpin fullscreen={true} />}>
  <Suspense>
    <Component {...props} />
  </Suspense>
)


const HomePage = Loadable(lazy(() => import('../pages/HomePage/HomePage')))
const LoginPage = Loadable(lazy(() => import('../pages/LoginPage/LoginPage')))
const SearchPage = Loadable(lazy(() => import('../pages/SearchPage/SearchPage')))
const SuccessPage = Loadable(lazy(() => import('@/pages/SuccessPage/SuccessPage')))
const InstallPage = Loadable(lazy(() => import('@/pages/InstallPage/InstallPage')))
const IntroPage = Loadable(lazy(() => import('../pages/IntroPage/IntroPage')))
const UninstallPage = Loadable(lazy(() => import('../pages/UninstallPage/UninstallPage')))
const SharePage = Loadable(lazy(() => import('../pages/SharePage/SharePage')))
/** H5 */
const H5SharePage = Loadable(lazy(() => import('../pages/H5SharePage/H5SharePage')))

export const router = createBrowserRouter([
  {
    path: '/',
    element: <PageContainer />,
    children: [
      {
        path: PAGE_LINKS.home,
        element: <HomePage />,
      },
      {
        path: PAGE_LINKS.login,
        element: <LoginPage />,
      },
      {
        path: PAGE_LINKS.search,
        element: <SearchPage />,
      },
      {
        path: PAGE_LINKS.success,
        element: <SuccessPage />,
      },
      {
        path: PAGE_LINKS.install,
        element: <InstallPage />,
      },
      {
        path: PAGE_LINKS.intro,
        element: <IntroPage />,
      },
      {
        path: PAGE_LINKS.uninstall,
        element: <UninstallPage />,
      },
      {
        path: PAGE_LINKS.share,
        element: <SharePage />,
      },

      // {
      // path: PAGE_LINKS.test,
      // element: <TestPage />,
      // },
    ],
  },
  {
    path: PAGE_LINKS.h5_share,
    element: <H5SharePage />
  }
])
