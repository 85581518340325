import { ConfigProvider, message, } from 'antd'
import { RouterProvider, } from 'react-router-dom'
import './App.less'
import { uiController } from './controllers/uiController'
import NiceModal from '@ebay/nice-modal-react'
import { router } from './Router/routes'


export default function App() {
  const [messageApi, contextHolder] = message.useMessage()
  uiController.message = messageApi
  return (
    <ConfigProvider
      autoInsertSpaceInButton={false}
      theme={{
        token: {
          colorPrimary: '#3872E0',
          colorText: '#212B36',
        },
      }}
    >
      <NiceModal.Provider>
        {/* <CommonDialogLayout /> */}
        {contextHolder}
        <RouterProvider router={router} />
      </NiceModal.Provider>
    </ConfigProvider>
  )
}

uiController.init()