import { userService } from '@/services/userService'
import { observer } from 'mobx-react'
import { useLayoutEffect } from 'react'
import { Outlet, useLocation, } from 'react-router-dom'
import { PAGE_LINKS } from '@/Router/pageLinks'
import { useNavigateWithParam } from '@/Router/Router'


const PageContainer = observer(() => {
  const location = useLocation()
  const navigate = useNavigateWithParam()
  useLayoutEffect(() => {
    if (userService.isInitialed) {
      if (!userService.isLogin) {
        const NEED_REDIRECT_PATH = [
          {
            path: PAGE_LINKS.success, check: () => true
          },
          {
            path: PAGE_LINKS.search, check: () => userService.userType === null
          }
        ]
        const findPath = NEED_REDIRECT_PATH.find(p => p.path === location.pathname)
        if (findPath && findPath.check()) {
          navigate('home', {}, true)
        }
      }
    }
  }, [location.pathname, userService.isLogin, userService.isInitialed, userService.userType])

  if (!userService.isInitialed) {
    return null
  }
  return <Outlet />
})

export default PageContainer
