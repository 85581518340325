import { ModalProps } from 'antd'
import { MessageInstance } from 'antd/es/message/interface'
import { makeObservable, observable } from 'mobx'

interface CommonModalProps extends ModalProps {
  content?: string
}
class UiController {
  @observable message!: MessageInstance
  @observable commonModalProps: CommonModalProps = { open: false }

  public init() {
    makeObservable(this)
  }
}

export const uiController = new UiController()
